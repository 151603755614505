<template>
  <div class="common-layout">
    <el-container>
      <el-aside class="common-aside">
        <div class="common-logo">
          <!-- <img src="@/assets/img/logo.png" /> -->
          <div class="fb">智能客服管理系统</div>
        </div>
        <el-menu :default-active="activeIndex" router>
          <el-menu-item
            index="MerchantList"
            :route="{ name: 'MerchantList' }"
          >
            <el-icon>
              <Shop/>
            </el-icon>
            <template #title>设施</template>
          </el-menu-item>
          <el-menu-item
            index="PlatformList"
            :route="{ name: 'PlatformList' }"
          >
            <el-icon>
              <Tools/>
            </el-icon>
            <template #title>平台</template>
          </el-menu-item>
          <el-menu-item
            index="SystemConfig"
            :route="{ name: 'SystemConfig' }"
          >
            <el-icon>
              <Setting/>
            </el-icon>
            <template #title>系统设置</template>
          </el-menu-item>
        </el-menu>
      </el-aside>
      <el-container>
        <el-header class="common-header">
          <div class="clearfix">
            <!-- <div class="fr mt20 pointer cRed">退出</div> -->
            <div class="fr pt15">
              <el-button
                type="primary"
                circle
                text
                :icon="SwitchButton"
                @click="logout"
              ></el-button>
            </div>
          </div>
        </el-header>
        <el-main>
          <router-view/>
        </el-main>
        <!-- <el-footer>Footer</el-footer> -->
      </el-container>
    </el-container>
  </div>
</template>
<script>
import { computed, onMounted } from '@vue/runtime-core'
import { useRoute, useRouter } from 'vue-router'
import { Setting, Shop, SwitchButton, Tools } from '@element-plus/icons-vue'
import store from '@/store'

export default {
  setup() {
    const route = useRoute()
    const router = useRouter()

    const activeIndex = computed(() => {
      return route.name
    })

    const removeParam = (key) => {
      sessionStorage.removeItem(key)
    }
    const logout = () => {
      sessionStorage.removeItem('authorization')
      router.replace({
        name: 'Login',
      })
    }

    const adminInfo = computed(() => {
      return store.getters.adminInfo
    })

    const goAdminEdit = () => {
      router.push({
        name: 'AdminEdit',
      })
    }

    onMounted(() => {
      const authToken = sessionStorage.getItem('authorization') || ''
      if (authToken === '') {
        router.replace({ name: 'Login' })
        return
      }
      store.dispatch('setConfig')
    })
    return {
      SwitchButton,
      Setting,
      Shop,
      Tools,
      activeIndex,
      removeParam,
      logout,
      adminInfo,
      goAdminEdit,
    }
  },
}
</script>
<style>
.common-layout,
.common-layout .el-container {
  height: 100%;
}

.common-header {
  border-bottom: 1px solid #dee2e6;
  background: #f8f9fa;
}

.common-aside {
  width: 200px;
  border-right: 1px solid #dee2e6;
}

.common-layout .el-menu {
  border-right: 0px;
}

.common-logo {
  padding: 20px 0;
  text-align: center;
}

.common-logo img {
  width: 50%;
}
</style>
